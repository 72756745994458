/* BUTTON */
.MuiButton-root.MuiButton-root {
  text-transform: none;
}
/* BUTTON CONTAINED */
.MuiButton-root.MuiButton-root {
  border-radius: 30px;
}
.MuiButton-containedPrimary.MuiButton-containedPrimary {
  background: #249ed8;
}
.MuiButton-containedPrimary.MuiButton-containedPrimary:hover {
  background: #0f4863;
}
.MuiButton-containedPrimary.MuiButton-containedPrimary {
  background: #249ed8;
}
.MuiButton-root.MuiButtonGroup-groupedOutlinedPrimary.MuiButtonGroup-groupedOutlinedPrimary:hover {
  background: #0f4863;
}

/* CHECKBOX */
.MuiCheckbox-root.MuiCheckbox-root {
  color: white;
}
.MuiCheckbox-root.Mui-checked.Mui-checked {
  color: white;
}

/* DIALOG */
.MuiDialog-root .MuiButton-root.MuiButton-root:hover {
  background-color: #0f4863;
}
.MuiDialog-root .MuiButton-textPrimary.MuiButton-textPrimary {
  color: #ffffff;
}

/* FAB */
.MuiFab-primary.MuiFab-primary {
  background: #249ed8;
}
.MuiFab-primary.MuiFab-primary:hover {
  background: #0f4863;
}

/* FORM HELPER TEXT */
.MuiFormHelperText-root.Mui-error.Mui-error {
  color: #f5aea9;
}

/* FORM LABEL */
.MuiFormLabel-root.MuiFormLabel-root {
  color: rgba(255,255,255,0.67);
}
.MuiFormLabel-root.Mui-focused.Mui-focused {
  color: #ffffff;
}
.MuiFormLabel-root.Mui-error.Mui-error {
  color: #f5aea9;
}

/* ICON BUTTON */
.MuiIconButton-root.MuiIconButton-root {
  color: #ffffff;
}

/* INPUT */
.MuiInput-underline.MuiInput-underline::before {
  border-bottom-color: rgba(255,255,255,0.67);
}
.MuiInput-underline.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: rgba(255,255,255,0.73);
}
.MuiInput-underline.MuiInput-underline::after {
  border-bottom-color: #ffffff;
}
.MuiInput-underline.Mui-error.Mui-error:after {
  border-bottom-color: #f5aea9;
}
.MuiInputBase-root.MuiInputBase-root {
  color: #ffffff;
}
.MuiInputBase-root.Mui-error.Mui-error {
  color: #f5aea9;
}

/* FILLED INPUT */
.MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-shrink {
  transform: translate(18px, -16px) scale(0.75);
}
.MuiInputLabel-filled.MuiInputLabel-filled {
  transform: translate(18px, 18px) scale(1);
}
.MuiFilledInput-input.MuiFilledInput-input {
  padding: 16px;
}
.MuiFilledInput-root.MuiFilledInput-root,
.MuiFilledInput-root.MuiFilledInput-root .MuiInputBase-input:-webkit-autofill {
  border-radius: 30px;
}
.MuiFilledInput-root.Mui-focused.Mui-focused {
  background: #0f4863;
}
.MuiFilledInput-underline.MuiFilledInput-underline::before {
  border-bottom: 0;
}
.MuiFilledInput-underline.MuiFilledInput-underline::after {
  border-bottom: 0;
}
.MuiFilledInput-underline.MuiFilledInput-underline:hover:before {
  border-bottom: 0;
}

/* LINEAR PROGRESS */
.MuiLinearProgress-colorPrimary.MuiLinearProgress-colorPrimary {
  background-color: #6ebbe0;
}
.MuiLinearProgress-barColorPrimary.MuiLinearProgress-barColorPrimary {
  background-color: #249ed8;
}

/* PICKERS */
.MuiPickersCalendarHeader-iconButton.MuiPickersCalendarHeader-iconButton {
  background-color: #26536d;
}
.MuiPickersDay-dayDisabled.MuiPickersDay-dayDisabled .MuiTypography-root {
  color: rgba(255,255,255,0.27);
}
.MuiPickersDay-daySelected.MuiPickersDay-daySelected {
  background-color: #249ed8;
}
.MuiPickersModal-dialogRoot.MuiPickersModal-dialogRoot {
  background-color: #26536d;
}
.MuiPickersMonth-monthDisabled.MuiPickersMonth-monthDisabled.MuiTypography-root {
  color: rgba(255,255,255,0.27);
}
.MuiPickersToolbar-toolbar.MuiPickersToolbar-toolbar {
  background-color: #266181;
}
.MuiPickersYear-yearDisabled.MuiPickersYear-yearDisabled.MuiTypography-root {
  color: rgba(255,255,255,0.27);
}

/* SELECT */
.MuiSelect-icon.MuiSelect-icon {
  color: rgba(255,255,255,0.67);
}
.Mui-focused .MuiSelect-icon.MuiSelect-icon {
  color: #ffffff;
}
.MuiSelect-select.MuiSelect-select:focus {
  background-color: transparent;
}

/* STEP CONTENT */
.MuiStepContent-root .MuiButton-outlinedPrimary.MuiButton-outlinedPrimary {
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.MuiStepContent-root
  .MuiButton-outlinedPrimary.MuiButton-outlinedPrimary:hover {
  color: #ffffff;
  border-color: #0f4863;
}

/* STEP ICON */
.MuiStepIcon-root.MuiStepIcon-root {
  color: #ffffff22;
  font-size: 25px;
}
.MuiStepIcon-root.MuiStepIcon-active.MuiStepIcon-active {
  color: #ffffff;
}
.MuiStepIcon-root.MuiStepIcon-completed.MuiStepIcon-completed {
  color: white;
}
.MuiStepIcon-active .MuiStepIcon-text.MuiStepIcon-text {
  fill: #249ed8;
}

/* STEP LABEL */
.MuiStepLabel-label.MuiStepLabel-label.MuiTypography-root {
  color: #ffffff77;
}
.MuiStepLabel-label.MuiStepLabel-active.MuiStepLabel-active {
  color: #ffffff;
}
.MuiStepLabel-label.MuiStepLabel-completed.MuiStepLabel-completed {
  color: #ffffff;
  font-weight: normal;
}
.MuiStepLabel-iconContainer.MuiStepLabel-iconContainer {
  align-self: flex-start;
}

/* TABLE */
.MuiTableBody-root .MuiTableRow-root.MuiTableRow-root:nth-child(odd) {
  background: rgba(36, 158, 216, 0.3);
}
.MuiTableBody-root .MuiTableRow-root.MuiTableRow-root:nth-child(odd) {
  background: rgba(36, 158, 216, 0.4);
}
.MuiTableCell-root.MuiTableCell-root {
  border: 0;
  vertical-align: top;
  white-space: pre-wrap;
}
.MuiTableHead-root .MuiTableRow-root.MuiTableRow-root:nth-child(odd) {
  background: rgba(36, 158, 216, 0.2);
}
.MuiTableCell-head.MuiTableCell-head {
  white-space: nowrap;
}

/* TYPOGRAPHY */
.MuiTypography-root.MuiTypography-root {
  color: #ffffff;
}
.MuiTypography-colorInherit.MuiTypography-colorInherit {
  color: inherit;
}
