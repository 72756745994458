.contentWrapper {
  display: grid;
  row-gap: 12px;
  padding: 0 12px;
}

.criteraName {
  margin-top: 0;
  margin-bottom: 12px;
  margin-left: 15px
}

.reportCritera {
  margin-top: 0;
  margin-bottom: 12px;
}

.formWrapper {
  display: grid;
  row-gap: 20px;
  margin-bottom: 20px;
}

.labelWrapper {
  display: grid;
  row-gap: 8px;
  padding: 0 12px;
}

.reportListItem {
  cursor: pointer;
  padding: 16px 0;
}

.reportListItem:last-child {
  border-bottom: 0;
}
