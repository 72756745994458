.rightSideDrawer.rightSideDrawer {
  background-color: #26536d;
  height: 100%;
  min-width: 500px;
  text-align: left;
}
.rightSideDrawerMobile.rightSideDrawerMobile {
  min-width: unset;
  width: 100vw;
}
.anchor.anchor {
  border-left: 0;
}
