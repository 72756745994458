.regions {
  padding: 32px;
}

.add_region_grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  margin-top: 15px;
  margin-bottom: 30px;
  align-items: baseline;
}

.region_name.region_name.region_name {
  margin-top: 4px;
}

.region_item {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 10px;
}