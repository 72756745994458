.wrapper {
  color: white;
}

.table_wrapper {
  width: 100%;
  overflow: auto;
  border-radius: 5px;
}

.table {
  background-color: rgba(108, 182, 243, 0.199);
  border-radius: 4px;
}

.table_title_bar {
  align-items: center;
  display: grid;
  column-gap: 32px;
  row-gap: 8px;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto auto;
  padding: 16px;
}
.table_title_mobile {
  padding: 16px 0;
  grid-template-columns: auto auto;
  grid-template-areas: "one one"
                      "two three";
}
.table_title_mobile > *:nth-child(1) {
  grid-area: one;
}
.table_title_mobile > *:nth-child(2) {
  grid-area: two;
}
.table_title_mobile > *:nth-child(3) {
  grid-area: three;
}

.text_wrapper.text_wrapper,
.text_wrapper.text_wrapper:hover {
  color: white;
}

.text_wrapper.text_wrapper svg {
  fill: white;
}

.active_head.active_head .text_wrapper.text_wrapper {
  color: #2883B1;
  font-weight: bold;
}

.active_head.active_head .text_wrapper.text_wrapper svg {
  fill: #2883B1;
}

.table_head.table_head {
  padding: 10px 15px;
}

.table_head.table_head h6 {
  font-size: 18px;
}
.table_head.table_head svg {
  font-size: 20px;
  margin-left: 8px;
}

.active_head.active_head {
  background-color: white;
  border-left: 2px solid white;
  border-right: 2px solid white;
  color: black;
}

.active_column.active_column {
  border-left: 2px solid white;
  border-right: 2px solid white;
}

.last_active_column.last_active_column {
  border-bottom: 2px solid white;
}

.table_cell.table_cell {
  padding: 12px 15px;
  vertical-align: middle;
  color: white;
}

.search_input {
  width: 190px;
  height: 42px;
}
.search_input::placeholder {
  color: rgb(173, 173, 173);
}
.search_input:focus {
  outline: none;
}

.addButton {
  justify-self: end;
}

.error,
.progress {
  grid-column: 1 / -1;
  grid-row: 2 / -1;
  position: relative;
  top: 50px;
}

.error.error {
  color: #f5aea9;
  text-align: left;
}

.vertical_center.vertical_center {
  vertical-align: middle;
  width: 30px;
}
