.title.title {
  margin-top: 45px;
}

.card {
  max-width: 600px;
  padding: 0px 20px;
}

.form {
  display: grid;
  grid-row-gap: 15px;
}

.cardElement {
  padding-bottom: 10px;
  margin-top: 10px;
  position: relative;
}

.cardElementUnderline {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(255, 255, 255, 0.67);
  pointer-events: none;
}

.cardElement:hover .cardElementUnderline {
  border-bottom: 2px solid white;
}

.payButton.payButton {
  width: 250px;
  margin: auto;
  margin-top: 15px;
}

.pageWrapper {
  padding: 0 16px;
}

.statusWrapper {
  margin-top: 65px;
}

.table_wrapper {
  width: 100%;
  overflow: auto;
  border-radius: 5px;
}

.tableCell.tableCell {
  vertical-align: middle;
  white-space: nowrap;
}

.prewrap {
  white-space: pre-wrap;
}

.addButton.addButton {
  float: right;
  margin-bottom: 20px;
}

.noPaymentMethods.noPaymentMethods {
  margin-top: 50px;
}

.clickHereText.clickHereText {
  margin-top: 20px;
}

.clickHere {
  cursor: pointer;
  color: white;
  padding-bottom: 3px;
  border-bottom: 1px solid white;
}

.error.error {
  white-space: pre-wrap;
  color: #f5aea9;
  margin-bottom: 10px;
}

.table {
  background-color: rgba(108, 182, 243, 0.199);
  border-radius: 4px;
}

.text_wrapper.text_wrapper,
.text_wrapper.text_wrapper:hover {
  color: white;
}

.text_wrapper.text_wrapper svg {
  fill: white;
}

.checkbox_grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
}

.checkbox_grid p {
  margin-top: 6px;
  margin-bottom: 8px;
}
