.form {
  display: grid;
  justify-items: center;
}

.fields {
  padding-bottom: 8px;
  width: 100%;
}

.forgotPassword {
  justify-self: end;
}

.buttonWrapper {
  width: 100%;
}

.link {
  text-decoration: none;
}

.error {
  white-space: pre;
}
