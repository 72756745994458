/* This file is specifically for style overrides related to printing the page */

@media print {

  .hide_print.hide_print {
    display: none;
  }

  .bold_print {
    font-weight: bold;
  }

  .avoid_page_break {
    display: table;
    width: 100%;
    page-break-inside: avoid;
    height: unset;
  }

  .MuiTypography-root.MuiTypography-root.MuiTypography-root,
  .MuiInput-root.MuiInput-root {
    color: black;
  }

  /* chart styles */
  .highcharts-axis-title,
  .highcharts-legend-item > text,
  .highcharts-axis-labels {
    fill: black;
  }

  .highcharts-tick,
  .highcharts-axis-line,
  .highcharts-grid-line.highcharts-grid-line {
    fill: none;
    stroke: black;
  }

  /* session details page */
  .session_details_page .highcharts-root.highcharts-root {
    width: calc(100% - 50px);
    position: relative;
    top: -30px;
  }

  .session_details_page .metric {
    margin-bottom: 0px;
  }

  .session_details_page .highlights_metric.highlights_metric {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .session_details_page .card.card {
    margin-top: 0px;
  }

  .session_details_page.session_details_page {
    grid-template-columns: auto minmax(0, 1fr);
  }

  .session_details_page .subject_icon.subject_icon svg {
    top: 0px;
  }

  .session_details_page .sidebar.sidebar.sidebar {
    background-color: white;
    border-right: 1px solid grey;
    padding-bottom: 10px;
    height: unset;
  }

  .session_details_page .subject_name.subject_name,
  .session_details_page .rank.rank,
  .session_details_page .unique_id.unique_id,
  .session_details_page .test_name.test_name,
  .session_details_page .platform.platform,
  .session_details_page .date.date,
  .session_details_page .select.select {
    position: relative;
    left: 10px;
  }

  /* reports page styles */
  .report_page_wrapper.report_page_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    text-align: left;
    color: black;
  }

  .report_page_wrapper .report_wrapper.report_wrapper {
    padding-top: 0px;
    height: unset;
    overflow: visible;
  }

  .report_page_wrapper .sidebar.sidebar.sidebar {
    height: unset;
    width: 100%;
    min-width: unset;
    max-width: unset;
    background-color: white;
  }

  .report_page_wrapper .sidebar.sidebar.sidebar div {
    background-color: white;
  }

  .report_page_wrapper .report_title.report_title {
    font-size: 30px;
  }

  .report_page_wrapper  .subtitle.subtitle.subtitle {
    margin-top: 0px;
    padding-top: 10px;
  }

  .report_page_wrapper .summary.summary.summary {
    margin-bottom: 0px;
  }

  .report_page_wrapper .critera_margin.critera_margin {
    margin-bottom: 5px;
  }

  .report_page_wrapper .highcharts-root.highcharts-root.highcharts-root {
    margin-top: 40px;
  }

  .report_page_wrapper .page_wrapper.page_wrapper.page_wrapper,
  .report_page_wrapper.report_page_wrapper.report_page_wrapper {
    overflow: visible;
  }

  .report_page_wrapper .sidebar.sidebar.sidebar > div {
    max-width: unset;
  }

  .report_page_wrapper .avoid_page_break {
    padding-top: 15px;
  }

  /* reports page stepper styles */
  .report_page_wrapper .MuiStepper-root.MuiStepper-root.MuiStepper-root {
    padding-top: 0px;
    padding-bottom: 25px;
    border-bottom: 1px solid grey;
    margin-bottom: 10px;
  }

  .report_page_wrapper .MuiStepIcon-root.MuiStepIcon-root  {
    color: #1c87e5
  }

  .report_page_wrapper .MuiStepIcon-root.MuiStepIcon-root text {
    fill: white;
  }

  .report_page_wrapper .MuiStepLabel-iconContainer.MuiStepLabel-iconContainer {
    display: none;
  }

  .report_page_wrapper .MuiStepLabel-labelContainer.MuiStepLabel-labelContainer > span > div {
    padding: 0px;
    padding-left: 5px;
    font-size: 16px;
  }

  .report_page_wrapper .MuiStepConnector-line.MuiStepConnector-line {
    display: none;
  }

  /* reports page table styles */
  .report_page_wrapper .MuiTableCell-root.MuiTableCell-root.MuiTableCell-root {
    color: black;
  }

  .report_page_wrapper .MuiTable-root.MuiTable-root {
    margin-top: 0px;
    padding-top: 0px;
  }

  .report_page_wrapper .MuiTableRow-head.MuiTableRow-head.MuiTableRow-head {
    background-color: #e0e0e0;
    border-bottom: 1px solid black;
  }

  .report_page_wrapper .MuiTableRow-root.MuiTableRow-root.MuiTableRow-root:nth-child(odd) {
    background-color: #e0e0e0;
  }
}
