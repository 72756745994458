.appBar {
  background-color: white !important;
  color: #222 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #ddd;
}
.outer {
  display: flex;
  min-height: 100vh;
}
.is_mobile.is_mobile {
  display: block;
}
.container {
  background: rgb(80, 162, 222);

  background: linear-gradient(
    90deg,
    rgba(37, 103, 138, 1) 0%,
    rgba(38, 102, 136, 1) 10%,
    rgba(39, 54, 69, 1) 100%
  );

  min-height: 100vh;
  width: calc(100% - 80px);
  position: relative;
}
.mobile_container {
  width: 100%;
  min-height: calc(100vh - 65px);
  position: relative;
}
.grow {
  flex-grow: 1;
}
.menuButton {
  margin-left: -6px !important;
  margin-right: 10px !important;
}
.person {
  margin-right: 8px;
}
.main {
  flex: 1;
}
.drawer {
  position: relative;
  width: 200px;
}
.drawerHeader {
  height: 64px;
}
