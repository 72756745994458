.react-select__control.react-select__control {
  background: transparent;
  border: 0;
  box-shadow: none;
}

.react-select__container .MuiInput-input {
  display: flex;
  height: unset;
}

.react-select__container .MuiInput-input > div > div:nth-child(2) {
  margin: 0;
  padding: 0;
}

.react-select__input.react-select__input {
  color: rgba(255,255,255,0.67);
}
.Mui-focused .react-select__input.react-select__input {
  color: #ffffff;
}

.MuiInput-focused .react-select__value-container {
  padding: 0;
}

.MuiInput-focused .react-select__single-value {
  margin: 0;
}

.MuiInput-no-label .react-select__clear-indicator {
  position: relative;
}

.react-select__clear-indicator {
  left: 10px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  top: 0;
  cursor: pointer;
}

.react-select__clear-indicator svg {
  color: rgba(255,255,255,0.67);
}

.react-select__clear-indicator:only-child {
  left: 0;
}

.react-select__dropdown-indicator.react-select__dropdown-indicator,
.react-select__dropdown-indicator.react-select__dropdown-indicator:hover
 {
  color: rgba(255,255,255,0.67);
  left: 5px;
  padding-right: 0;
  position: relative;
}
.Mui-error .react-select__dropdown-indicator.react-select__dropdown-indicator {
  color: #f5aea9;
}

.Mui-focused
  .react-select__dropdown-indicator.react-select__dropdown-indicator {
  color: #ffffff;
}

.Mui-error .react-select__placeholder.react-select__placeholder {
  color: #f5aea9;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__menu {
  background: #ffffff;
  width: calc(100% + 18px);
  z-index: 2;
}

.react-select__placeholder.react-select__placeholder {
  color: rgba(255,255,255,0.67);
  margin-left: 0;
}
.Mui-error .react-select__placeholder.react-select__placeholder {
  color: #f5aea9;
}

.react-select__single-value.react-select__single-value {
  color: #ffffff;
  margin: 0;
}

.Mui-focused .react-select__single-value.react-select__single-value {
  color: #ffffff;
}

.react-select__value-container {
  flex-wrap: unset;
  padding-left: 0 !important;
}

.react-select__value-container {
  padding: 0;
}

.react-select__multi-value__remove.react-select__multi-value__remove {
  cursor: pointer;
  border-radius: 0px 15px 15px 0px;
  padding: 0px 5px;
}
.react-select__multi-value__remove svg {
  color: black;
}
.react-select__container .react-select__multi-value.react-select__multi-value.react-select__multi-value {
  background-color: white;
  border-radius: 25px;
  margin: 2px;
}
.react-select__container .react-select__multi-value__label.react-select__multi-value__label {
  padding: 5px 5px 5px 10px;
}
