.page_wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  text-align: left;
}
.page_wrapper_mobile {
  grid-template-columns: 1fr;
  position: relative;
  height: calc(100vh - 65px);
  overflow: auto;
}
.sidebar {
  height: 100vh;
  width: 500px;
  overflow: auto;
}
.sidebar_mobile {
  min-width: unset;
  max-width: unset;
  padding-bottom: 15px;
  display: grid;
  height: min-content;
  width: 100%;
  overflow: unset;
}
.sidebar_mobile.sidebar_mobile > div {
  padding: 15px;
  max-width: 400px;
}

.sidebar > div {
  padding: 32px 25px;
}

.report_display {
  position: relative;
}
