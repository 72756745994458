.no_results {
  padding-top: 100px;
}

.no_results img {
  width: 250px;
  margin-bottom: 50px;
}

.no_results h4 {
  margin-bottom: 20px;
}
