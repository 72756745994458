.tutorial_videos {
  text-align: left;
  padding: 32px;
  color: white;
  max-width: 800px;
}

.logos_grid {
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.logos_grid_single {
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.tutorial_videos_mobile .logos_grid,
.tutorial_videos_mobile .logos_grid_single {
  padding-top: 30px;
  grid-template-columns: 1fr;
  padding-bottom: 20px;
}

.logo {
  max-width: 220px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tutorial_videos_mobile {
  padding: 20px;
}

.tutorials_title {
  padding-bottom: 30px;
}

.tutorial_videos_mobile .tutorials_title {
  padding-bottom: 10px;
}

.video_wrapper {
  padding: 50px 0px;
}

.tutorial_videos_mobile .video_wrapper {
  padding: 30px 0px;
}

.video_wrapper:last-child {
  padding-bottom: 0px;
}

.video_wrapper p {
  margin-top: 15px;
  margin-bottom: 25px;
}
