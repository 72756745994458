/*  TODO remove unused styles from copy */
.appBar {
  background-color: white !important;
  color: #222 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #ddd;
}
.outer {
  display: flex;
}
.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-height: 100vh;
  flex: 1;
  -webkit-flex: 1;
  left: 200px;
}
.grow {
  flex-grow: 1;
}
.menuButton {
  margin-left: -6px !important;
  margin-right: 10px !important;
}
.person {
  margin-right: 8px;
}
.main {
  height: 100%;
  min-height: 100vh;
  position: relative;
}
.drawer {
  position: relative;
  width: 200px;
}
.drawerHeader {
  height: 64px;
}
