.session_videos {
  padding: 24px;
  text-align: left;
}

.session_videos_mobile {
  padding: 15px;
}

.video_link {
  cursor: pointer;
}

.video_player {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 70%;
}

.video_player_mobile {
  width: 100%;
}
