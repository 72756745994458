.pageWrapper {
  padding: 32px;
}
.pageWrapperMobile {
  padding: 0 15px 15px 15px;
}
.subjectName {
  color: #ffffff;
  display: grid;
  column-gap: 16px;
  align-items: center;
}

.rank.rank {
  color: #e2e2e2;
}

.uniqueId.uniqueId {
  color: #b1e9ff;
}

.sessions.sessions {
  color: #c7c7c7;
}
