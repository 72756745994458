.input {
  background: rgba(0,47,85,0.51);
  border: 0;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  line-height: 21px;
  padding-left: 24px;
}
.input:focus {
  outline: none;
}