.cell.cell {
  color: white;
  vertical-align: middle;
}

.header.header {
  color: white;
  vertical-align: middle;
  font-size: 16px;
}

.star {
  color: yellow;
}

.star_red {
  color: #ff5f5f;
  display: inline-grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 0px auto;
}
.table_wrapper {
  overflow: auto;
  width: 100%;
}
.table_wrapper_tablet {
  overflow: auto;
  width: calc(100vw - 64px);
}
.table_wrapper_mobile.table_wrapper_mobile {
  overflow: auto;
  width: calc(100vw - 30px);
}

.table_wrapper.table_wrapper.table_wrapper * {
  overflow: hidden;
}
