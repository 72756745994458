.report_wrapper {
  padding: 32px;
  height: calc(100vh - 64px);
  overflow: auto;
}
.report_wrapper_tablet {
  overflow: unset;
}
.report_wrapper_mobile {
  overflow: unset;
  padding: 20px 15px;
  overflow: unset;
}

.title.title {
  margin-bottom: 15px;
}

.subtitle.subtitle {
  margin-top: 50px;
  margin-bottom: 25px;
}

.summary.summary {
  white-space: pre-wrap;
  margin-bottom: 40px;
}

.result_wrapper {
  margin-top: 20px;
  margin-bottom: 15px;
}
