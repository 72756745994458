body,
input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

@font-face {
  font-family: "Segoe UI";
  src: url("https://amp.azure.net/libs/amp/2.3.3/skins/amp-default/assets/fonts/segoeui/segoeui.woff")
    format("woff");
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f6fa;
  min-height: 100%;
  min-height: 100vh;
}
#root {
  min-height: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.highcharts-credits {
  display: none;
}

/* Placeholder styles must be seperated or they aren't applied */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b3bcbf;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #b3bcbf;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #b3bcbf;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #b3bcbf;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

/* Hide native clear icon in Edge */
input::-ms-clear {
  display: none;
}

/* overrides for webkit autofill and inputs / Stripe */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px none inset;
  transition: background-color 5000s ease-in-out 0s;
}

input.ElementsApp.is-autofilled .InputElement:not(.is-empty),
.ElementsApp.is-autofilled .InputElement:not(.is-empty),
input.ElementsApp.is-autofilled .InputElement:-webkit-autofill,
.ElementsApp.is-autofilled .InputElement:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  color: white !important;
  -webkit-box-shadow: 0 0 0px 1000px #26536d inset;
  transition: background-color 5000s ease-in-out 0s;
}

iframe input,
.StripeElement.StripeElement--webkit-autofill,
.StripeElement.StripeElement--webkit-autofill input,
input.InputElement.is-complete.Input {
  -webkit-text-fill-color: white !important;
  color: white !important;
  background-color: #26536d !important;
}
