.pagination_wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr 200px;
  grid-gap: 15px;
  justify-items: center;
  align-items: center;
  padding: 10px 15px;
}

.pagination_wrapper_mobile {
  grid-template-columns: 1fr;
}

.pagination_pages {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
}

.pagination_pages > span {
  display: grid;
  cursor: pointer;
  align-items: center;
  justify-items: center;
  padding: 5px;
  background-color: #297198;
  border-radius: 5px;
  height: 22px;
  min-width: 22px;
  margin: 0px 1px;
}

.pagination_pages .active {
  background-color: #249ed8;
}

.pagination_pages .disabled {
  cursor: not-allowed;
  opacity: 0.75;
}

.rows_per_page {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-items: center;
  grid-gap: 10px;
}
