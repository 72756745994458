.sessionDetails {
  color: white;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  height: 100%;
}
.session_details_mobile {
  grid-template-columns: 1fr;
}

.loading {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.card.card {
  text-align: left;
  padding: 24px;
}
.card_mobile.card_mobile {
  padding: 15px;
}
.cardContent {
  position: relative;
}

.light.light {
  color: #e2e2e2;
  font-size: 14px;
  line-height: 1.4;
  text-transform: none;
}

.title.title {
  color: white;
}

.select.select {
  text-align: left;
  width: 150px;
}

.chartCard.chartCard {
  background-color: transparent;
  grid-column: 2 / -1;
  grid-row: 2 / -1;
}
.chartHeading {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: max-content 1fr;
  padding-bottom: 24px;
}

.highlights.highlights {
  background: transparent;
  grid-template-columns: repeat(3, auto);
  justify-items: center;
  margin: 16px 0px;
}
.highlightsMetrics {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-items: center;
  width: 100%;
}
.metrics_mobile {
  grid-template-columns: 1fr;
  grid-gap: 40px;
}
.highlightsMetric {
  display: grid;
  justify-items: center;
  margin-bottom: 100px;
  position: relative;
}
.metricImageWrapper {
  height: 200px;
  position: absolute;
  left: -16px;
  top: 45px;
  width: 200px;
  z-index: 0;
}
.metricImage {
  left: 0;
  position: absolute;
  top: 45px;
}
.metricImageBackground {
  background: #266f94;
  border-radius: 50%;
  left: 0;
  height: 137px;
  position: absolute;
  top: 10px;
  width: 137px;
}
.metricTitle.metricTitle {
  font-size: 18px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  z-index: 10;
}
.metricValueWrapper {
  align-items: center;
  display: grid;
  font-size: 48px;
  grid-template-columns: repeat(2, auto);
  justify-items: center;
  z-index: 10;
  position: relative;
}
.metricValueWrapper svg {
  font-size: 48px;
}
.metricValue {
  justify-self: start;
}
.metricIcon {
  justify-self: end;
}
.metricValueUp.metricValueUp {
  color: #90cd57;
}
.metricValueDown.metricValueDown {
  color: #ff9e9e;
}
.metricValueSame.metricValueSame {
  color: #c7c7c7;
}

.personDetails {
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  height: 100%;
  text-align: left;
}
.person_details_mobile {
  min-width: unset;
  max-width: unset;
  padding-bottom: 15px;
}
.personDetailsHeader {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  padding: 24px 24px 0 0;
}
.header_mobile {
  padding: 10px 24px 0 15px;
  grid-template-columns: auto 1fr;
}
.backButton.backButton {
  background-color: #257198;
  position: relative;
  transform: translateX(-30px);
  z-index: 10000;
  height: 58px;
  width: 61px;
  position: relative;
  top: -10px;
}
.back_mobile.back_mobile {
  z-index: 1;
  width: 58px;
  top: 0px;
  transform: none;
  left: -10px;
}
.backButton.backButton:hover {
  background-color: #0188bd;
}
.print_button.print_button {
  float: right;
  position: relative;
  top:  40px;
  right: 25px;
}
.personDetail {
  padding: 0 24px;
}
.spacer {
  height: 24px;
}
.spacer_mobile {
  height: 15px;
}
.indent {
  padding-left: 28px;
}
.rank {
  padding-left: 30px;
  padding-bottom: 0px;
}
.uniqueId {
  padding-left: 30px;
  padding-bottom: 5px;
}
.subjectName.subjectName {
  align-content: center;
  display: grid;
  grid-gap: 8px;
  margin-top: 15px;
  grid-template-columns: max-content 1fr;
  position: relative;
  left: -5px;
}
.subjectName svg {
  position: relative;
  top: -2px;
}
.testNameWrapper {
  align-items: center;
  display: grid;
  grid-gap: 8px;
  margin-top: 10px;
  grid-template-columns: max-content 1fr;
}
.testNameWrapper p {
  font-size: 14px;
}
.chartColorIcon.chartColorIcon {
  height: 15px;
  width: 15px;
}
.chartColor0 {
  color: #91cd57;
}

.chartColor1 {
  color: #f0b725;
}

.chartColor2 {
  color: #3ee1ec;
}

.chartColor3 {
  color: #ec25f0;
}

.chartColor4 {
  color: #8085e9;
}

.chartColor5 {
  color: #f15c80;
}

.chartColor6 {
  color: #e4d354;
}

.chartColor7 {
  color: #2b908f;
}

.chartColor8 {
  color: #f45b5b;
}

.chartColor9 {
  color: #91e8e1;
}
