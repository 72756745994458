.azure_mp_container {
  background: #000;
  width: 100%;
  height: 0;
  display: block;
  padding: 0;
  position: relative;
  overflow: hidden;
  position: relative;
}

.azure_mp_container > div {
  position: absolute;
  width: 100%;
  height: 100%;
}
