.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255, 0.2);
  z-index: 10000;
}

.spinner > div {
  position: absolute;
  left: calc(50% - 20px);
  top: 25vh;
}

.spinner svg {
  color: white;
  width: 40px;
}
