.drawer {
  background-color: #249ed8;
  height: 100vh;
  position: relative;
  width: 80px;
}
.drawer > div {
  background-color: #249ed8;
}
.drawerAnchor.drawerAnchor {
  border-right: 0;
}
.icon_wrapper {
  height: 70px;
  display: grid;
  justify-items: center;
  align-items: center;
  cursor: pointer;
}

.icon_wrapper:hover {
  background-color: rgba(38,102,137, 0.3);
}

.nav_buttons {
  display: grid;
  grid-template-rows: auto auto auto auto 1fr auto auto;
  padding-top: 54px;
  height: calc(100vh - 54px);
}

.logout {
  width: 80px;
  background-color: #249ed8;
}

.icon {
  color: white;
}

.icon_button.icon_button,
.icon_button.icon_button:hover {
  padding-top: 8px;
  background-color: transparent;
}

.active_page,
.active_page:hover {
  background-color: #266689;
}

.icon_label {
  font-size: 12px;
  color: white;
  position: relative;
  top: -6px;
  text-transform: capitalize;
}

.mobile_nav {
  display: grid;
  height: 65px;
  grid-template-columns: auto 1fr auto;
  grid-gap: 15px;
  background-color: #249ed8;
  justify-items: center;
  align-items: center;
  padding: 0px 25px;
}

.mobile_drawer {
  position: absolute;
  top: 66px;
  height: calc(100vh - 65px);
}
.mobile_drawer > div {
  top: unset;
  position: relative;
}
.mobile_drawer > div > div {
  padding-top: 0px;
}

.mobile_nav svg {
  fill: white;
}

.logo {
  width: 140px;
}
