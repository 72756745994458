.page_wrapper {
  display: flex;
}
.page_wrapper_mobile {
  display: grid;
  height: 100%;
}
.content_outer{
  position: relative;
  min-height: 100vh;
}
.content_wrapper {
  margin: 24px 24px 0px 24px;
  width: calc(100vw - 400px);
}
.content_wrapper_tablet {
  margin: 24px 24px 0px 24px;
  width: calc(100vw - 48px);
}
.content_wrapper_mobile {
  margin: 0px 15px 15px 15px;
  width: calc(100vw - 30px);
}
.sidebar_content_tablet {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto auto auto;
}
.sidebar_content_mobile {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}
.sidebar {
  height: 100vh;
  min-width: 275px;
  max-width: 350px;
}
.sidebar_mobile {
  min-width: unset;
  max-width: unset;
  padding-bottom: 15px;
  display: grid;
  height: unset;
}
.sidebar_title {
  margin-top: 22px;
}
.sidebar > div {
  padding: 24px 24px 0;
}
.sidebar_mobile > div {
  padding: 0px 15px;
}
.sidebar_item_wrapper {
  color: white;
  margin: 24px 0px;
}
.sidebar_item_wrapper_mobile {
  margin: 0;
}
.sidebar_item_wrapper h5 {
  font-size: 22px;
  font-weight: 400;
}
.sidebar_item {
  border-radius: 5px;
  margin: 0px 35px 0px 8px;
  padding: 10px 12px;
  cursor: pointer;
}
.sidebar_item_mobile {
  margin: 0px auto;
  text-align: center;
  max-width: 250px;
}
.sidebar_item:hover {
  background-color: #016086;
}
.sidebar_item_active {
  background-color: rgba(36, 158, 216, 0.4);
}
.spacer {
  height: 10px;
}
