.buttons {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
}

.stepper.stepper {
  padding: 32px 0;
  background-color: #257198;
}
