.pageWrapper {
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(37, 103, 138, 1) 0%,
    rgba(38, 102, 136, 1) 10%,
    rgba(39, 54, 69, 1) 100%
  );
  display: grid;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}
.pageWrapper:before {
  background: url(/img/big-background-dots.svg);
  background-position: center right -620px;
  background-repeat: no-repeat;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

.logo {
  width: 200px;
}

.contentWrapper {
  align-items: center;
  background: rgba(0,160,223,0.08);
  border-radius: 50%;
  display: grid;
  justify-content: center;
  height: 400px;
  padding: 48px;
  width: 400px;
  position: relative;
  z-index: 10;
}

.formWrapper {
  width: 100%;
}
