.sessions {
  color: white;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
}
.sessions_mobile {
  padding: 0px;
  display: grid;
  grid-template-columns: 1fr;
}

.text_wrapper.text_wrapper.text_wrapper {
  color: white;
}

.text_wrapper.text_wrapper svg {
  fill: white;
}

.active_head.active_head .text_wrapper.text_wrapper {
  color: #2883B1;
  font-weight: bold;
}

.active_head.active_head .text_wrapper.text_wrapper svg {
  fill: #2883B1;
}

.table_cell.table_cell {
  padding: 0px 10px;
  vertical-align: middle;
  white-space: nowrap;
}

.checkbox_cell.checkbox_cell {
  width: 35px;
  padding: 5px 10px;
}

.checkbox_cell.checkbox_cell > span {
  padding: 5px;
}

.table_head.table_head {
  padding: 8px 10px;
  vertical-align: middle;
}

.table_head.table_head h6 {
  font-size: 18px;
}
.table_head.table_head svg {
  font-size: 20px;
  margin-left: 8px;
}

.active_head.active_head {
  background-color: white;
  border-left: 2px solid white;
  border-right: 2px solid white;
  color: black;
}

.active_column.active_column {
  border-left: 2px solid white;
  border-right: 2px solid white;
}

.last_active_column.last_active_column {
  border-bottom: 2px solid white;
}

.rows {
  background: linear-gradient(
    90deg,
    rgba(38, 114, 153, 1) 25%,
    rgba(39, 77, 99, 1) 100%
  );
  border-radius: 4px;
  overflow: auto;
  margin-bottom: 10px;
}
.rows_mobile {
  padding: 0px;
}
.row {
  align-items: center;
  display: flex;
  white-space: nowrap;
}

.group_row {
  display: grid;
  grid-template-columns: auto auto auto auto 1fr;
  grid-column-gap: 10px;
  margin-bottom: 15px;
  align-items: center;
  justify-items: left;
  white-space: nowrap;
}

.nowrap {
  display: flex;
  white-space: nowrap;
}

.headerRow {
  border-radius: 4px;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr;
  justify-items: start;
  margin-bottom: 16px;
  margin-top: 20px;
}
.tabletHeader {
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr;
  height: unset;
}
.mobileHeader {
  grid-gap: 15px;
  grid-template-columns: 1fr;
  height: unset;
  padding: 15px 15px 5px 15px;
  margin: 0px 0px 10px 0px;
}

.textField {
  font-size: 16px;
  width: 190px;
  height: 42px;
}
.textFieldMobile {
  font-size: 16px;
  width: calc(100% - 20px);
  height: 42px;
}
.inputLabel {
  font-size: 0.85em !important;
  text-align: left;
  width: 150px;
}

.multiselect {
  text-align: left;
  color: black;
  width: 100%;
}

.multiselect label {
  font-size: 18px;
}

.platform {
  color: white;
  font-weight: 400;
}
.rank,
.date {
  color: #e2e2e2;
  margin-left: 32px;
  text-align: left;
  font-weight: 400;
  width: 200px;
}
.group_row_item {
  color: #e2e2e2;
  margin-left: 60px;
  padding-right: 15px;
  text-align: left;
  font-weight: 400;
}

.name {
  align-items: center;
  display: flex;
  white-space: nowrap;

  width: 300px;
}
.uniqueId {
  color: #b1e9ff;
  font-size: 15px;
  font-weight: 400;
  margin-left: 32px;
}

.results.results {
  background: transparent;
  color: white;
}

.compare.compare {
  background-color: #249ed8;
  border-radius: 50px;
  height: 45px;
  text-transform: none;
  width: 180px;
}

.menuButton.menuButton {
  margin-right: 10px;
  padding: 6px;
}

.datepicker input::-webkit-input-placeholder {
  opacity: 1;
}

.multiselect :global(.react-select__placeholder) {
  color: white;
}

.expand_icon.expand_icon {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  width: 35px;
  height: 35px;
}

.expand_icon_rotate.expand_icon_rotate {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 35px;
  height: 35px;
}

.show_more {
  margin: 10px 0px 15px 51px;
  cursor: pointer;
  text-align: left;
}

.full_width {
  width: 100%;
}

.sidebar {
  width: 325px;
  text-align: left;
  height: 100vh;
  overflow-y: auto;
}

.sidebar_mobile {
  width: 100%;
  max-width: unset;
  display: grid;
  height: unset;
}

.sidebar > div {
  padding: 32px;
}

.sidebar_mobile > div {
  padding: 15px;
}

.sidebar_mobile > div > h4 {
  margin-left: 15px;
}

.table_wrapper {
  max-height: calc(100vh - 10px);
  overflow-y: auto;
  padding-right: 20px;
  padding-bottom: 10px;
}

.table_wrapper_mobile {
  padding: 0px 10px 10px 10px;
  max-height: unset;
}

.table_actions {
  display: grid;
  grid-template-columns: auto 1fr auto;
  margin: 25px 0px 15px 0px;
  height: 45px;
}

.table_actions_mobile {
  margin-top: 0px;
}
