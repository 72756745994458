.textWrapper {
  align-items: center;
  display: grid;
  row-gap: 32px;
  text-align: left;
}

.email {
  color: #b1e9ff;
}

.error.error {
  color: #f5aea9;
}

.hidden.hidden {
  display: none;
  width: 100%;
}
